import React from "react";
import './blog.css'


import { navigate } from "gatsby";
export default function Pagination({ currentPage, numPages }) {
  const handleClick = (page) => {
    navigate(`/blog?page=${page}`);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2rem",
      }}
    >
      {Array.from({ length: numPages }, (_, i) => i + 1).map((page) => (
        <button
          className={`pagination-button ${
            currentPage === page ? "active" : ""
          }`}
          key={page}
          onClick={() => handleClick(page)}
          disabled={currentPage === page}
        >
          {page}
        </button>
      ))}
    </div>
  );
}
