import React, { useState, useEffect } from "react";
import {
  Divider,
  Typography,
  Grid,
  CardActions,
  // Fade,
} from "@material-ui/core";
import { faUser, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import Logo from "../Logo/Logo";
import imgL1Logo from "../../assets/image/genify-logo-main.webp";
import { Fade } from "react-bootstrap";
import "./blog.css";
// import { useLocation } from "react-router-dom";

// convert date from 2023-05-31T00:40:37+02:00 to May 31, 2023
const convertDate = (date) => {
  const newDate = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return newDate.toLocaleDateString("en-US", options);
};

const WordpressCards = (props) => {
  return (
    <div className="blogsGrid">
      {props.currentPosts.map((post) => (
        <div key={post.ID} className="bg-white rounded-lg shadow flex flex-col">
          <img
            className="rounded-lg"
            src={post.thumbnail ?? imgL1Logo}
            alt={post.title}
            style={{ height: "200px", objectFit: "cover" }}
          />
          <Link to={`/blog/${post.slug}`}>
            <h2 style={{ fontSize: "1rem" }} className="font-bold mb-2 p-4">
              {post.title}
            </h2>
          </Link>
          <p
            className="text-gray-700 p-4"
            dangerouslySetInnerHTML={{
              __html:
                post.excerpt
                  .slice(0, 100)
                  .replace(/<p/g, '<p style="font-size: 1rem"') + "...",
            }}
          ></p>
          <div className="flex-1"></div>
          <Divider />
          <CardActions className="p-4">
            <Typography
              style={{
                fontSize: "14px",
                color: "#212529",
              }}
              display="block"
              variant="body1"
              gutterBottom
            >
              {/* <FontAwesomeIcon icon={faUser} />  */}
              <div className="flex gap-2 items-center">
                <img
                  style={{ height: 24, objectFit: "cover" }}
                  src={post.author.avatar_URL}
                  alt=""
                />
                {post.author.name}
              </div>
            </Typography>
            <div className="flex-1"></div>
            <Typography
              display="block"
              variant="body1"
              style={{
                fontSize: "14px",
                color: "#868e96",
                display: "inline-block",
              }}
              gutterBottom
            >
              <FontAwesomeIcon icon={faCalendarAlt} /> {convertDate(post.date)}
            </Typography>
          </CardActions>
        </div>
      ))}
    </div>
  );
};

export default WordpressCards;
