import React, { useEffect } from "react";
import MediumCards from "../components/BlogComponents/MediumCards";
import { Helmet } from "react-helmet";
import PageWrapper from "../components/PageWrapper";
import WordpressCards from "../components/BlogComponents/WordpressCards";
import { useLocation } from "@reach/router";
import axios from "axios";
import Pagination from "../components/BlogComponents/Pagination";
import { Col } from "react-bootstrap";
import { navigate } from "gatsby";

const Blog = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentPage = parseInt(searchParams.get("page")) || 1;

  const [posts, setPosts] = React.useState([]);
  const perPage = 9;
  const startIndex = (currentPage - 1) * perPage;
  const endIndex = startIndex + perPage;

  const [categories, setCategories] = React.useState(["All"]);
  const [selectedCategory, setSelectedCategory] = React.useState("All");

  const filteredPosts =
    selectedCategory == "All"
      ? posts
      : selectedCategory
      ? posts.filter((post) => post.category === selectedCategory)
      : posts;
  const pagePosts = filteredPosts.slice(startIndex, endIndex);
  const numPages = Math.ceil(filteredPosts.length / perPage);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    navigate(`/blog?page=1`);
  };

  const fetchPosts = async () => {
    let timesCalled = 0;
    let aggregatePosts = [];
    let lastFound = 0;

    do {
      const response = await axios.get(
        "https://public-api.wordpress.com/rest/v1.1/sites/thrthrtwhergtwrhwtrhwtrh.wordpress.com/posts?number=" +
          perPage +
          "&offset=" +
          timesCalled * 9
      );
      timesCalled++;
      aggregatePosts = [...aggregatePosts, ...response.data.posts];
      lastFound = response.data.found;
    } while (lastFound >= perPage);

    setPosts(
      (posts) => [
        ...posts,
        ...aggregatePosts.map((post) => {
          const attachmentsId = Object.keys(post.attachments)[0];
          const ctg = Object.keys(post.terms.category)[0];

          return {
            ID: post.ID,
            title: post.title,
            excerpt: post.excerpt,
            author: post.author,
            date: post.date,
            slug: post.slug,
            // thumbnail: thumbnail,
            thumbnail: post.featured_image,
            category: ctg,
          };
        }),
      ]
      // .filter((post) => post.author !== "ismailf989d924c8")
    );
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    const categories = [...new Set(posts.map((post) => post.category))];
    setCategories(["All", ...categories]);
  }, [posts]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        url: "https://genify.ai/",
        path: "/blog",
        page_type: "blog",
        event: "page_view",
        title: "Genify Blog",
      });
    }
  }, []);

  return (
    <>
      <Helmet defer={false}>
        <link rel="canonical" href="https://www.genify.ai/blog/" />
        <title>Blog - Fintech & Analytics News | Genify</title>
        <meta
          name="description"
          content="Discover what's new regarding fintech and AI in banking on Genify's blog. Click now for more information and in-depth insights."
        />
      </Helmet>

      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null", // cta, account, null
          path: "blogPage",
        }}
        footerConfig={{
          style: "blog", //style1, style2
        }}
      >
        <div
          className="pt-13 pt-lg-24 pb-lg-24 bg-default-1"
          // style={{ marginTop: "5%" }}
        >
          <section style={{ padding: "3em 10vw" }}>
            <h1
              style={{
                fontSize: "64px",
                lineHeight: "70px",
                fontWeight: "700",
                textAlign: "left",
                marginBottom: "30px",
                textAlign: "center",
                marginBlock: "3rem",
              }}
            >
              The <span style={{ color: "#4A63A5" }}>Genify </span>Blog
            </h1>
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                marginBlock: "2rem",
                flexWrap: "wrap",
              }}
            >
              {/* <h4>Categories</h4> */}
              {categories.map((category) => (
                <button
                  key={category}
                  onClick={() => handleCategoryClick(category)}
                  className={`pagination-button ${
                    selectedCategory === category ? "active" : ""
                  }`}
                >
                  {category}
                </button>
              ))}
            </div>
            {posts.length === 0 && (
              <Col
                xs={12}
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </Col>
            )}
            <WordpressCards currentPosts={pagePosts} />
            {/* <MediumCards /> */}
            <Pagination numPages={numPages} currentPage={currentPage} />
          </section>
        </div>
      </PageWrapper>
    </>
  );
};

export default Blog;
